span.about10span {
    background: rgba(0, 125, 231, 0.10);
    padding: 8px 12px;
    border-radius: 5px;
    color: #015c91;
    font-weight: 500;
}

.service10-boxarea {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    transition: all .4s ease-in-out;
    background: rgba(0, 125, 231, 0.10);
    padding: 32px 20px;
    border-radius: 6px;
    height: 330px;
}

.ts {
    transition: all .4s ease-in-out;
}

.welcome-ellipsbox {
    position: relative;
    margin-bottom: 24px;
    margin-top: 25px;
}

.elips-icon6 {
    background: #6083A9;
    display: inline-block;
    height: 72px;
    width: 72px;
    line-height: 72px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.margin-b {
    margin-bottom: 16px;
    margin-top: 50px;
}

.listStyle {
    list-style: circle;
    text-align: initial;
    margin-bottom: 10px;
    color: #56605F;
}

.service10-boxarea::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    background: #6083A9;
    height: 30px;
    width: 30px;
    z-index: -1;
    border-radius: 100px 0 0 0;
    transition: all .4s ease-in-out;
}

.service10-boxarea:hover {
    transform: translateY(-10px);
    transition: all .4s ease-in-out;
}

.service10-boxarea:hover::after {
    height: 100%;
    width: 100%;
    transition: all .4s ease-in-out;
    border-radius: 4px;
}

.listStyle:hover::after {
    list-style: circle;
    text-align: initial;
    margin-bottom: 10px;
    /* color: white; */
}

.service10-boxarea:hover .elips-icon6 {
    background: rgb(48, 77, 102);
    transition: all .4s ease-in-out;
}

.service10-boxarea:hover .font-lora {
    color: #fff;
    transition: all .4s ease-in-out;
}

.service10-boxarea:hover .listStyle {
    color: #fff;
    font-weight: 500;
    transition: all .4s ease-in-out;
}

.service10-boxarea .font-lora {
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
    color: rgba(32, 64, 99, 0.8);
    font-size: 20px;
    font-weight: 600
}

.service10-boxarea:hover p {
    color: rgba(255, 255, 255, 0.90);
    transition: all .4s ease-in-out;
}

@media (min-width:770px) and (max-width:1399px) {
    .service10-boxarea {
        height: 425px;
    }
}
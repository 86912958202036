


.counter2-section-area{
  position: relative;
}

.counter2-section-area{
  background: #E9EDF4;
  padding: 80px 0;
}

.countre2-boxarea{
  border-radius: 4px;
  background: #FFF;
  min-width: 270px;
  min-height: 169px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .4s ease-in-out;
}

#setCounter{
  color: #015c91;
  font-weight: 600;
  font-size: 17px;
}

@media (max-width:480px) {
  #colMargin{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

